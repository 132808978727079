<template>
  <PageHeader
    :logo-link="logoLink"
    :logo-text="$globalPageSettings?.companyName ?? ''"
    :main-navigation="mainNavigationItems"
    :nav-top-items="navTopItems"
    :nav-action-bar-items="navActionBarItems"
    :search-label="searchLabel"
    :search-title="searchTitle"
    :search-box="searchBox"
    :cart-quick-view="cartQuickView"
    :login-quick-view="loginQuickView"
    @cart-item-remove="cartWidgetStore.handleRemove"
    @search-value-change="handleSearchValueChange"
    @search-form-submit="handleSearchFormSubmit"
    @search-type-change="handleSearchTypeChange"
  />
  <ShopCartToastContainer />
</template>

<script setup lang="ts">
import { PageHeader } from '@hypercodestudio/basler-components';
import type { MainNavigationInterface } from '@hypercodestudio/basler-components/dist/models/navigation/MainNavigation';
import type { NavTopItem } from '@hypercodestudio/basler-components/dist/models/navTopItem';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { useCreateMainNavItem } from '~/composables/useCreateMainNavItem';
import { useBuildLinkInterface } from '~/composables/useBuildLinkInterface';
import { isDefined } from '~/utils/guards/isDefined';
import type {
  LinkFragment,
  NavigationItemFragment
} from '~/lib/ContentfulGraphqlService';
import { isNavigationItemFragment } from '~/utils/guards/isNavigationItemFragment';
import { deepExtract } from '~/utils/deepExtract';
import { useSearchBox } from '~/composables/useSearchBox';
import { convertContentfulToWebappLocaleCode } from '~/utils/convertContentfulToWebappLocaleCode';
import { useCartWidgetStore } from '~/stores/cartWidgetStore/cartWidgetStore';
import { useCartStore } from '~/stores/cartStore/cartStore';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';
import { useHTMLDictionary } from '~/composables/useHTMLDictionary';
import { buildUrlString } from '~/utils/buildUrlString';
import { TYPE } from 'vue-toastification';

const {
  $globalPageSettings,
  $shopStore,
  $breakpoints,
  $textDictionary,
  $locale
} = useNuxtApp();
const htmlDictionary = useHTMLDictionary([
  'pageHeader.loginQuickView.introduction.text'
]);
const slug = useSlug();
const cartStore = useCartStore();
const geoinformationStore = useGeoinformationStore();
const logger = useLogger();
const route = useRoute();
const router = useRouter();
const { loggedIn, user } = useOidcAuth();
const { showToast } = useShowToast();

watchEffect(() =>
  cartStore.$patch((state) => {
    state.store = $shopStore.value;
    state.locale = $locale.value;
  })
);

const {
  searchBox,
  handleSearchFormSubmit,
  handleSearchTypeChange,
  handleSearchValueChange
} = useSearchBox($globalPageSettings);

const footerLink = computed(() =>
  useBuildLinkInterface($globalPageSettings.value?.cartFooterLink)
);
const noItemsLink = computed(() =>
  useBuildLinkInterface($globalPageSettings.value?.cartNoItemsLink)
);

const cartWidgetStore = useCartWidgetStore();

watchEffect(() =>
  cartWidgetStore.$patch((state) => {
    state.footerLink = footerLink.value;
    state.noItemsLink = noItemsLink.value;
  })
);

const mainNavigation = computed(
  () =>
    $globalPageSettings.value?.headerMainNavigation?.navigationItemsCollection
      ?.items
);

const firstLevelParentNavigationItemIndex = computed(() => {
  if (!slug.value) {
    return -1;
  }

  let parents: NavigationItemFragment[] = [];

  if ($globalPageSettings.value) {
    deepExtract(
      $globalPageSettings.value.headerMainNavigation,
      (value, _, _parents, stop) => {
        if (
          isNavigationItemFragment(value) &&
          value.internalPage?.metadata?.slug === slug.value
        ) {
          parents = [..._parents.filter(isNavigationItemFragment), value];
          stop();
        }

        return false;
      }
    );
  }

  return mainNavigation.value?.findIndex(
    (item) =>
      item?.internalPage?.metadata?.slug ===
      parents[0]?.internalPage?.metadata?.slug
  );
});

const mainNavigationItems = computed<MainNavigationInterface>(() => ({
  showTabs: mainNavigation.value
    ?.map((item, index) => (item?.displayedAsTabs ? index : -1))
    .filter((item) => item !== -1),
  items:
    mainNavigation.value
      ?.map((item, index) =>
        useCreateMainNavItem(
          item,
          index === firstLevelParentNavigationItemIndex.value
        )
      )
      .filter(isDefined) ?? [],
  backButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.back'] ?? 'Back',
  nextButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.next'] ?? 'Next',
  closeButtonLabel:
    $textDictionary.value['pageHeader.navigation.mobile.close'] ?? 'Close'
}));

// region Begin Workaround for QuickCartView:
// we MUST keep the reference to this array and only manipulate
// the entries inside to get the cart widget to update.
const cartsRef = ref([...cartWidgetStore.carts]);
const cartQuickView = computed(() => ({
  carts: cartsRef,
  noItems: cartWidgetStore.noItems,
  isOpen: true
}));

watch(
  () => cartWidgetStore.carts,
  () => {
    cartsRef.value.splice(0, cartsRef.value.length);
    cartsRef.value.push(...cartWidgetStore.carts);
  }
);
// End Workaround
// endregion

function mapLink(link: LinkFragment) {
  return {
    title: link.linkText,
    icon: link.linkIcon
      ? {
          name: link.linkIcon
        }
      : undefined,
    link: buildLinkInterface(link, $locale.value)
  };
}

const loginQuickView = computed(() => {
  if (process.server || $locale.value === 'zh-cn') {
    return undefined;
  }

  const currentQueryParams = new URLSearchParams(
    route.query as Record<string, string>
  );
  const loginRedirectQueryParams = currentQueryParams.toString();
  currentQueryParams.append('loggedOut', '');
  const logoutRedirectQueryParams = currentQueryParams.toString();

  return {
    isOpen: true,
    // logged out params
    introText:
      htmlDictionary.value['pageHeader.loginQuickView.introduction.text'],
    ctaLinks: [
      {
        title:
          $textDictionary.value[
            'pageHeader.loginQuickView.actions.login.label'
          ] ?? 'Login with Basler ID',
        styleType: 'attraction',
        link: {
          uri: buildUrlString(
            '',
            '/auth/entra/login',
            `redirect=${encodeURIComponent(
              route.path +
                (loginRedirectQueryParams.length > 0
                  ? '?' + loginRedirectQueryParams
                  : '')
            )}`,
            undefined,
            true,
            false
          ),
          target: '_self'
        } satisfies HyperlinkInterface
      },
      {
        title:
          $textDictionary.value[
            'pageHeader.loginQuickView.actions.register.label'
          ] ?? 'Register personal Basler ID',
        styleType: 'link',
        link: {
          uri: buildUrlString(
            '',
            '/auth/entra/login',
            `redirect=${encodeURIComponent(
              route.path +
                (loginRedirectQueryParams.length > 0
                  ? '?' + loginRedirectQueryParams
                  : '')
            )}`,
            undefined,
            true,
            false
          ),
          target: '_self'
        } satisfies HyperlinkInterface
      }
    ],
    isLoggedIn: loggedIn.value,
    // loggedin params
    profile: {
      company: user.value?.claims?.Company,
      firstName: user.value?.userInfo?.given_name,
      lastName: user.value?.userInfo?.family_name,
      email: user.value?.userInfo?.email
    },
    linkGrid: {
      items: (
        $globalPageSettings.value?.accountWidgetLinkGridCollection?.items ?? []
      )
        .filter(isDefined)
        .map(mapLink),
      numberOfColumns: 2
    },
    linkList: (
      $globalPageSettings.value?.accountWidgetLinkListCollection?.items ?? []
    )
      .filter(isDefined)
      .map(mapLink),
    footer: {
      linkItem: {
        title:
          $textDictionary.value[
            'pageHeader.loginQuickView.actions.logout.label'
          ] ?? 'Logout',
        link: {
          uri: buildUrlString(
            '',
            '/auth/entra/logout',
            `redirect=${encodeURIComponent(
              route.path +
                (logoutRedirectQueryParams.length > 0
                  ? '?' + logoutRedirectQueryParams
                  : '')
            )}`,
            undefined,
            true,
            false
          )
        } satisfies HyperlinkInterface
      }
    }
  };
});

const navTopItems = computed(
  () =>
    [
      ...($globalPageSettings.value?.headerServiceNavigation?.navigationItemsCollection?.items
        .filter(
          (item) => !isDefined(item?.showInNavigation) || item?.showInNavigation
        )
        .map(
          (item) =>
            ({
              label: item?.linkText ?? '',
              // XXX: wrong type in Hypercode components?
              link: useBuildLinkInterface(item) as any,
              iconName: item?.icon ?? ''
            } satisfies NavTopItem)
        ) ?? []),
      {
        label: geoinformationStore.currentRegionLabel,
        link: {
          uri: {
            // without the path the link does not work on the homepage
            path: '.',
            hash: '#regionselector'
          }
        } satisfies HyperlinkInterface,
        iconName: 'globe'
      }
    ] satisfies NavTopItem[]
);

// the cart links needs the absolute url to be treated as default link
// (not <RouterLink>).
const cartLink = computed(() => {
  if (
    !cartWidgetStore.currentCartLink ||
    // desktop should not have a basket link
    !$breakpoints.specialHeaderBreakpoint
  ) {
    return {};
  }

  return {
    uri: cartWidgetStore.currentCartLink,
    target: '_self',
    external: true
  };
});

const navActionBarItems = computed(() => {
  let userInitials;

  if (loggedIn.value && user.value?.userInfo) {
    userInitials =
      ((user.value?.userInfo?.given_name as string)[0] ?? '').toUpperCase() +
      ((user.value?.userInfo?.family_name as string)[0] ?? '').toUpperCase();
  }

  return [
    {
      name: 'search',
      type: 'search',
      title: 'Search',
      link: useBuildLinkInterface('/search/'),
      iconName: 'action_bar_magnifier'
    },
    {
      name: 'cart',
      type: 'cart',
      title: $textDictionary.value['cart.cart.title.label'] ?? 'Cart',
      link: cartLink.value,
      iconName: 'action_bar_shopping_cart',
      badgeNumber: cartWidgetStore.cartItemCount
    },
    {
      name: 'profile',
      type: 'login',
      title:
        $textDictionary.value['pageHeader.actions.shopLogin'] ?? 'Shop login',
      link: profileLink.value,
      iconName: loggedIn.value ? undefined : 'action_bar_profile',
      userInitials
    }
  ];
});

const searchLabel = computed(() => 'Search...');
const searchTitle = computed(() => 'Search');
const logoLink = computed(() => ({
  uri: `/${convertContentfulToWebappLocaleCode($locale.value)}/`
}));
const profileLink = computed(() => {
  return {
    uri: $locale.value === 'zh-cn' ? cartStore.profileLink : '',
    target: '_self',
    external: true
  };
});

onMounted(() => {
  // this loads all carts for which a cookie value exists.
  // we trigger this on the client side as this is user data and should not
  // be cached (e.g. in a cdn).
  cartStore.init().catch((e) => {
    // the cart store has internal error handling. just in case wrap with an
    // additional try-catch.
    logger.error('PageHeader', 'could not load cart', e);
  });
  if ('loggedOut' in route.query) {
    const message =
      $textDictionary.value['auth.loggedout.message'] ??
      'Successfully logged out';

    showToast(message, {
      type: TYPE.SUCCESS
    });

    const currentQuery = { ...route.query };

    delete currentQuery['loggedOut'];
    router.push({ query: currentQuery });
  }
});

// TODO: Remove css code, this is just to fix the order of classes caused by broken bundling from hypercode
</script>

<style>
.login-quick-view__link-grid-item-link {
  color: #fff;
  text-decoration: none;
}

.login-quick-view__footer-link {
  color: #fff9;
  text-decoration: none;
}

.login-quick-view__list-item-link.link:hover,
.login-quick-view__list-item-link.link:focus-visible {
  --color-icon: white;
  color: #fff;
}

.login-quick-view__link-grid-item-link:hover,
.login-quick-view__link-grid-item-link:focus-visible {
  --color-icon: white;
  color: #fff;
}
</style>
